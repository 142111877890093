export const buymeacoffee = "https://www.buymeacoffee.com/alexcwarren";
export const contactme =
  "https://us12.list-manage.com/contact-form?u=a0893bd472ec99083ffec4e5e&form_id=7b741951a1482747b1d37900ebd554f3";
export const facebook = "https://www.facebook.com/alex.ch.warren/";
export const github = "https://github.com/alexcwarren";
export const instagram = "https://instagram.com/alexcwarren";
export const kofi = "https://ko-fi.com/alexcwarren";
export const linkedin = "https://www.linkedin.com/in/alexandercwarren/";
export const mailchimp = "https://eepurl.com/h7yAS9";
export const medium = "https://medium.com/@alexcwarren";
export const tiktok = "https://www.tiktok.com/@alexcwarren";
export const twitter = "https://twitter.com/alexcwarren";
export const youtube =
  "https://www.youtube.com/channel/UCW8yGtbtIbVs3sPYnOS1RgA";
